<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <!-- collapse  -->
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <div class="collapse-title px-4 items-center">
                        <div class="title">
                            狀態 : {{ info.status | complaintStatus }}
                            <i class="ml-2 fas fa-chevron-down"></i>
                        </div>
                    </div>
                </template>
                <div v-for="log in logs.slice().reverse()" :key="log.created_at" class="collapse-item">
                    <span>{{ log.status | complaintStatus }}：</span> {{ log.created_at | formatDateTime }} ｜
                    <span v-if="log.administrator">{{ log.administrator.name }}</span>
                    <span v-else-if="log.user">{{ log.user.name }}</span>
                </div>
            </el-collapse-item>
        </el-collapse>
        <!-- 資訊 -->
        <div class="detail-info bg-white rounded-lg mt-4">
            <div class="salary-info">
                <!-- 客訴資訊 -->
                <div class="section-title">客訴資訊</div>
                <div class="section-content">
                    <div class="top">
                        <div class="top-left">
                            <div class="order-ids">
                                <div class="item">
                                    <div class="label">編號</div>
                                    <div class="val">
                                        {{ info.id }}
                                        <span class="icon ml-2 cursor-pointer" @click="copy(info.id)">
                                            <i class="far fa-clone"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">訂單編號</div>
                                    <div class="val" :class="{ 'org-link': info.dating ? true : false }" @click="openTarget('order', info.dating.id)">
                                        {{ info.dating ? info.dating.order_id : "--" }}
                                    </div>
                                </div>
                            </div>
                            <div class="input">
                                <label class="text-gray-400">
                                    問題分類
                                    <el-select v-if="canEdit" v-model="inputs.type" class="w-full mt-2" clearable>
                                        <el-option v-for="(status, index) in typeOptions" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                    <div v-else class="text-black mt-1">
                                        {{ info.type | complaintType }}
                                    </div>
                                </label>
                            </div>
                            <div class="input">
                                <label class="text-gray-400">
                                    緊急程度
                                    <el-select v-if="canEdit" v-model="inputs.severity" class="w-full mt-2" clearable>
                                        <el-option
                                            v-for="(status, index) in severityOptions"
                                            :key="index"
                                            :label="status.label"
                                            :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                    <div v-else class="text-black mt-1">
                                        {{ info.severity | complaintSeverity }}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="order-ids">
                                <div class="item">
                                    <div class="label">發起人</div>
                                    <div v-if="info.user" class="val org-link" @click="openTarget('user', info.user_id)">
                                        {{ info.user ? info.user.name : "--" }}
                                    </div>
                                    <div v-else class="val">--</div>
                                </div>
                                <div class="item">
                                    <div class="label">客訴對象</div>
                                    <div v-if="info.defendant" class="val org-link" @click="openTarget('defendant', info.defendant_id)">
                                        {{ info.defendant ? info.defendant.name : "--" }}
                                    </div>
                                    <div v-else class="val">--</div>
                                </div>
                            </div>
                            <div class="input">
                                <label class="text-gray-400">
                                    狀態
                                    <el-select v-if="canEdit" v-model="inputs.status" class="w-full mt-2" clearable>
                                        <el-option v-for="(status, index) in statusOption" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                    <div v-else class="text-black mt-1">
                                        {{ info.status | complaintStatus }}
                                    </div>
                                </label>
                            </div>
                            <div v-if="canEdit && showInputOrderID" class="input">
                                <label class="text-gray-400">
                                    訂單編號
                                    <el-input v-model="inputs.order_id" placeholder="請輸入訂單編號" class="w-full mt-2" clearable> </el-input>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="input">
                            <div class="label">問題描述</div>
                            <div class="val">
                                <span v-if="info.email">{{ info.email }} <br /></span>{{ info.feedback }}
                            </div>
                        </div>
                        <div class="input">
                            <div class="label">截圖</div>
                            <div class="val">
                                <div class="images">
                                    <div
                                        v-for="(i, idx) in inputs.images"
                                        :key="idx"
                                        :src="i"
                                        class="image"
                                        :class="{ 'border-orange': i.isNewUpload }"
                                        :style="{ background: `url('${i.url}') no-repeat center` }">
                                        <div class="watch">
                                            <div class="icon" @click="handlePictureCardPreview(idx)">
                                                <i class="fas fa-search-plus"></i>
                                            </div>
                                            <div v-if="i.isNewUpload" class="icon ml-5" @click="deleteIMG(idx)">
                                                <i class="far fa-trash-alt"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 上傳圖片區塊 -->
                                    <el-upload
                                        v-if="inputs.images.length < 5"
                                        action="#"
                                        :limit="10"
                                        multiple
                                        :auto-upload="false"
                                        :on-change="changeFile"
                                        accept=".jpg, .jpeg, .png"
                                        list-type="none"
                                        :file-list="fileList">
                                        <button v-if="canEdit" v-permission="['update']" class="btn-upload">+ 其他圖片</button>
                                        <div slot="file" class="flex"></div>
                                    </el-upload>
                                </div>
                            </div>

                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" />
                                <div class="actions2" style="border: none">
                                    <el-button icon="el-icon-caret-left" circle @click="handlePictureCardPreview(showImageIndex - 1)"></el-button>
                                    <el-button icon="el-icon-caret-right" circle @click="handlePictureCardPreview(showImageIndex + 1)"></el-button>
                                </div>
                            </el-dialog>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button class="black-btn-outline-lg text-md mr-5" @click="backList">返回列表</button>
                <el-button v-if="canEdit" v-permission="['update']" class="orange-btn-800-lg text-md" :disabled="disabled" @click="update"
                    >資料更新</el-button
                >
            </div>
            <div class="salary-info" style="margin-top: 40px">
                <div class="section-title">回報處理情況 <el-checkbox v-model="showSignificant" class="ml-2">顯示重點註記</el-checkbox></div>
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>內容</th>
                            <th>客服人員</th>
                            <th>註記時間</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, idx) in supportLogs" :key="idx" :class="{ hasSign: i.significant }">
                            <td>{{ i.note }}</td>
                            <td>{{ i.administrator ? i.administrator.name : i.administrator_id }}</td>
                            <td>{{ i.created_at | formatDateTime }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-permission="['update']" class="submit flex">
                    <el-input v-model="inputNote" placeholder="請輸入註記內容" clearable> </el-input>
                    <el-button
                        class="orange-btn-outline-800 md:text-sm text-xs w-[100px] h-[40px] ml-3"
                        :disabled="!inputNote"
                        @click="updatFeebackLog"
                        >送出註記</el-button
                    >
                </div>
                <el-checkbox v-model="inputSignificant" v-permission="['update']" class="ml-2 mt-2">重點註記</el-checkbox>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import LoadingComponent from "@/components/Loading.vue";
// options
import orderComplaintConfig from "@/config/orderComplaintConfig";
import "@/global/filters/filterComplaint";
// base64 轉 Blob
import { dataURLtoBlob } from "@/service/imageTransfer.js";
export default {
    name: "CustomerComplaintDetail",
    components: {
        LoadingComponent,
    },
    computed: {
        // 問題 options
        typeOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 警急程度 options
        severityOptions() {
            const status = orderComplaintConfig.complaintSeverity;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 狀態
        statusOption() {
            const status = orderComplaintConfig.complaintStatus;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 畫面顯示註記排序
        supportLogs() {
            // 重點註記
            let significants = [...this.info.support_logs.filter((i) => i.significant === 1)];
            significants = significants.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());

            // 非重點註記
            let allSignificants = [...this.info.support_logs];
            allSignificants = allSignificants.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());

            return this.showSignificant ? significants : allSignificants;
        },
        canEdit() {
            return !(this.info.status == 3);
        },
        showInputOrderID() {
            return this.inputs.type == "3" || this.inputs.type == "7";
        },
        disabled() {
            if (this.inputs.type == "3" || this.inputs.type == "7") {
                return this.inputs.order_id ? false : true;
            }
            return false;
        },
    },
    data() {
        return {
            loading: false,
            // 當前客訴資訊
            id: 0,
            info: {
                support_logs: [],
                user: {},
                defendant: {},
                media: {},
            },
            logs: [],
            // 編輯後資訊
            inputs: {
                type: "",
                status: "",
                severity: "",
                order_id: "",
                images: [
                    // {
                    //     isNewUpload: false, // 是否為編輯後新上傳
                    //     url: ''
                    // }
                ],
            },
            fileList: [],
            // 圖片預覽彈窗相關
            showImageIndex: 0,
            dialogImageUrl: "",
            dialogVisible: false,
            // 註記相關
            inputNote: "",
            inputSignificant: false, // 重點註記輸入匡
            showSignificant: false,
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            const { status, data } = await this.$api
                .GetUserFeedback(this.id)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.info = data;
                this.inputs = {
                    type: String(data.type),
                    status: String(data.status),
                    severity: String(data.severity),
                    order_id: data.dating?.order_id,
                    images: [],
                };
                data.media.forEach((i) => {
                    this.inputs.images.push({
                        isNewUpload: false,
                        url: i,
                    });
                });
            }
        },
        async getLogs() {
            const { status, data } = await this.$api
                .GetFeedbackLogs(this.id)
                .then((res) => {
                    return { data: res.data.logs, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                });
            if (status === 200) {
                this.logs = [...data];
            }
        },
        // 更新資料
        update() {
            this.loading = true;
            const { type, status, severity, images, order_id } = this.inputs;

            const result = new FormData();
            result.append("type", type);
            result.append("status", status);
            result.append("severity", severity);
            if (order_id) {
                result.append("order_id", order_id);
            }

            images.forEach((i) => {
                if (i.isNewUpload) {
                    const blob = dataURLtoBlob(i.url);
                    result.append("images[]", blob);
                }
            });

            this.$api
                .UpdateUserFeeback(this.info.id, result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getData();
                    this.getLogs();
                })
                .catch((err) => {
                    console.log(err);
                    const errMessage = err.response?.data.error.message ? err.response?.data.error.message : "更新失敗";
                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 更新註記
        updatFeebackLog() {
            this.loading = true;
            this.$api
                .UpdatFeebackLog(this.info.id, { note: this.inputNote, significant: this.inputSignificant ? 1 : 0 })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getData();
                    this.inputNote = "";
                    this.inputSignificant = false;
                })
                .catch((err) => {
                    console.log(err);
                    const errMessage = err.response.data.error.message;
                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 更新桌機圖片
        changeFile(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList = [];
                alert("檔案大小超出5mb");
                return;
            }
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                const fileStamp = {
                    isNewUpload: true,
                    url: reader.result,
                };
                if (this.inputs.images.length < 5) {
                    this.fileList = fileList.slice(-3);
                    this.inputs.images.push(fileStamp);
                } else {
                    alert("檔案張數超出限制");
                    return;
                }
            };
        },
        // 移除上傳圖片
        deleteIMG(idx) {
            this.inputs.images.splice(idx, 1);
        },
        handlePictureCardPreview(idx) {
            this.showImageIndex = idx >= 0 ? idx % this.inputs.images.length : this.inputs.images.length - 1;
            this.dialogImageUrl = this.inputs.images[this.showImageIndex].url;
            this.dialogVisible = true;
        },
        // 導頁相關
        backList() {
            this.$router.push({
                name: "customer-complaint-list",
            });
        },
        openTarget(type, id) {
            if (type === "order") {
                const userID = this.info.user_id;
                window.open(`/#/${userID}/PaymentFlowList/member_update/order_detail/${id}`);
            } else if (type === "defendant") {
                if (this.info.defendant.role == "1" || this.info.defendant.role == "2") {
                    window.open(`/#/user_list/provider_update/${id}/Profile`);
                } else {
                    window.open(`/#/user_list/member_update/${id}/Profile`);
                }
            } else {
                if (this.info.user.role == "1" || this.info.user.role == "2") {
                    window.open(`/#/user_list/provider_update/${id}/Profile`);
                } else {
                    window.open(`/#/user_list/member_update/${id}/Profile`);
                }
            }
        },
        // others
        copy(text) {
            const dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.$message({ type: "success", message: "複製成功" });
        },
    },
    watch: {
        "inputs.type": {
            handler(val) {
                if (val == 3 || val == 7) {
                    this.inputs.order_id = this.info.dating?.order_id;
                } else {
                    this.inputs.order_id = "";
                }
            },
        },
    },
    mounted() {
        this.id = this.$route.params.customerID;
        this.getData();
        this.getLogs();
    },
};
</script>
<style lang="scss" scrope>
@import "@/scss/_text-style.scss";

.collapse-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.el-collapse-item__arrow {
    display: none;
}

.collapse-item {
    span {
        color: #757575;
    }
}

.el-collapse-item__content {
    padding-bottom: 10px;
    padding: 0 20px 10px 20px;
}

.el-collapse-item__wrap {
    padding: 0 20px;
}

.org-link {
    color: #ff5733;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    &.no-line {
        text-decoration: none;
    }
}

.border-orange {
    border: 3px dashed #ff5733;
}

.detail-info {
    padding: 30px 8%;
    margin-bottom: 50px;
    .section-title {
        width: 80%;
        margin: 20px 0;
        @extend .text-black-blod-18;
    }
    .section-content {
        margin-top: 20px;
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
        .top {
            display: flex;
            width: 100%;
            .top-left {
                flex: 1;
            }
            .top-right {
                flex: 1;
            }
            .order-ids {
                display: flex;
                .item {
                    flex: 1;
                    flex-direction: column;
                }
            }
            .label {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .input {
                width: 80%;
                margin-top: 20px;
                label {
                    font-size: 14px;
                }
            }
        }

        .bottom {
            margin-top: 10px;
            width: 100%;
            .input {
                margin-top: 20px;
                text-align: left;
            }

            .label {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
            }
        }

        .item {
            display: flex;
            margin: 10px 0;
            .section-key {
                @extend .text-gray-medium-14;
                width: 150px;
            }
            .section-value {
                @extend .text-black-regular-14;
            }
        }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
        border-bottom: 2px solid #cbcbcb;
    }

    .actions2 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .myTable {
        border-radius: 20px;
        border: 1px solid #ccc;
        text-align: left;
    }

    .submit {
        margin-top: 20px;
    }
}

.images {
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    .image {
        display: flex;
        position: relative;
        width: 150px;
        min-width: 150px;
        height: 150px;
        margin: 10px 5px;
        background-size: contain !important;
        .icon:hover {
            cursor: pointer;
        }
        &:hover {
            .watch {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
            .trash {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
        }
        .watch {
            display: none;
        }
        .trash {
            display: none;
        }
    }
}

.hasSign {
    background: #ff573324 !important;
}

.btn-upload {
    border: 3px dashed #ff5733;
    color: #ff5733;
    width: 150px;
    height: 150px;
    margin: 10px 5px;
}

.el-upload-list {
    display: none;
}
</style>
